"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.setPositionItems = void 0;
const store_1 = require("../store");
const setPositionItems = (matrix, arr) => {
    for (let y = 0; y < matrix.length; y++) {
        for (let x = 0; x < matrix.length; x++) {
            const value = matrix[y][x];
            const node = arr.find(nodeItem => nodeItem.dataset.matrixId === String(value));
            if (node === undefined)
                return;
            setNodeStyles(node, x + 1, y + 1);
        }
    }
    checkCurrentTile(matrix, store_1.fifteen.winMatrix).forEach((currentValue) => addCurrentTileClass(currentValue));
    // setTimeout(() => checkWin(matrix, fifteen.winMatrix), 500)
};
exports.setPositionItems = setPositionItems;
const setNodeStyles = (node, x, y) => {
    const position = `tile-position-${x}-${y}`;
    node.className = `tile ${position}`;
};
const checkCurrentTile = (matrix, matrixWin) => {
    const currentField = [...matrix].flat();
    const currentWinField = [...matrixWin].flat();
    const currentValue = [];
    for (let i = 0; i < currentField.length; i++) {
        if (currentField[i] === currentWinField[i]) {
            currentValue.push(currentWinField[i]);
        }
    }
    return currentValue;
};
const addCurrentTileClass = (currentTile) => {
    const tiles = document.querySelectorAll('.tile');
    tiles.forEach((tile) => {
        const matrixId = Number(tile.dataset.matrixId);
        if (matrixId === currentTile && matrixId !== 0) {
            tile.classList.add('tile_correct');
        }
    });
};
