"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.longSwap = exports.swap = void 0;
const store_1 = require("../store");
const helper_1 = require("./helper");
const swap = (coords1, coords2, matrix) => {
    const coords1Number = matrix[coords1.y][coords1.x];
    matrix[coords1.y][coords1.x] = matrix[coords2.y][coords2.x];
    matrix[coords2.y][coords2.x] = coords1Number;
};
exports.swap = swap;
const swapRight = (row, targetTile, zeroTile, blackCoords) => {
    const blank = row.slice(blackCoords.x);
    const currentSize = row.length - 1;
    if ((targetTile === 0) && (zeroTile === currentSize)) {
        const startRow = row.slice(0, store_1.fifteen.size);
        return (0, helper_1.firstZeroConcat)(startRow, blank, currentSize);
    }
    if ((targetTile === 0) && (zeroTile !== currentSize)) {
        const startRow = row.slice(0, zeroTile);
        startRow.unshift(0);
        const endRow = row.slice(zeroTile + 1, store_1.fifteen.size);
        return [].concat(startRow, endRow);
    }
    else {
        const startRow = row.slice(0, targetTile);
        const endRow = row.slice(targetTile, store_1.fifteen.size).filter((tile) => tile !== 0);
        endRow.unshift(0);
        return [].concat(startRow, endRow);
    }
};
const swapLeft = (row, targetTile, zeroTile) => {
    const currentSize = row.length - 1;
    if ((targetTile === currentSize) && (zeroTile === 0)) {
        const newRow = row.filter((tile) => tile !== 0);
        newRow.push(0);
        return newRow;
    }
    if ((zeroTile !== 0) && (targetTile === currentSize)) {
        const startRow = row.slice(0, zeroTile);
        const endRow = row.slice(zeroTile + 1, store_1.fifteen.size);
        endRow.push(0);
        return [].concat(startRow, endRow);
    }
    if ((zeroTile !== 0) && (targetTile !== currentSize)) {
        const startRow = row.slice(0, targetTile + 1).filter((tile) => tile !== 0);
        const endRow = row.slice(targetTile + 1, store_1.fifteen.size);
        startRow.push(0);
        return [].concat(startRow, endRow);
    }
    else {
        const startRow = row.slice(1, targetTile + 1);
        const endRow = row.slice(targetTile + 1, store_1.fifteen.size);
        startRow.push(0);
        return [].concat(startRow, endRow);
    }
};
const swapBottom = (matrix, targetCoords, x) => {
    const blankCoords = (0, helper_1.findCoordByNumber)(store_1.fifteen.blankTile, matrix);
    let counterY = blankCoords.y;
    while (counterY !== 0) {
        const blankCoords = (0, helper_1.findCoordByNumber)(store_1.fifteen.blankTile, matrix);
        const tile = matrix[counterY][x];
        const nextTile = matrix[counterY - 1][x];
        if (blankCoords.y > targetCoords.y) {
            matrix[counterY - 1][x] = tile;
            matrix[counterY][x] = nextTile;
        }
        --counterY;
    }
};
const swapTop = (matrix, targetCoords, x) => {
    const blankCoords = (0, helper_1.findCoordByNumber)(store_1.fifteen.blankTile, matrix);
    let counterY = blankCoords.y;
    while (counterY !== targetCoords.y) {
        const tile = matrix[counterY][x];
        const nextTile = matrix[counterY + 1][x];
        matrix[counterY + 1][x] = tile;
        matrix[counterY][x] = nextTile;
        ++counterY;
    }
};
const longSwap = (blankCoords, targetCoords, matrix) => {
    for (let y = 0; y < matrix.length; y++) {
        for (let x = 0; x < matrix.length; x++) {
            const targetTile = targetCoords.x;
            const zeroTile = blankCoords.x;
            if (y === targetCoords.y) {
                if (zeroTile > targetTile) {
                    return matrix[y] = swapRight(matrix[y], targetTile, zeroTile, blankCoords);
                }
                if (zeroTile < targetTile) {
                    return matrix[y] = swapLeft(matrix[y], targetTile, zeroTile);
                }
                if (x === zeroTile) {
                    if (blankCoords.y > targetCoords.y) {
                        swapBottom(matrix, targetCoords, x);
                    }
                    if (blankCoords.y <= targetCoords.y) {
                        swapTop(matrix, targetCoords, x);
                    }
                }
            }
        }
    }
};
exports.longSwap = longSwap;
