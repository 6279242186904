"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.updateLocalCounter = exports.updateLocalStorage = exports.counterStorage = exports.fifteenStorage = exports.matrixStorage = exports.sizeStorage = void 0;
exports.sizeStorage = localStorage.getItem('size');
exports.matrixStorage = localStorage.getItem('matrix');
exports.fifteenStorage = localStorage.getItem('fifteen');
const counterStorage = () => JSON.parse(localStorage.getItem('counter'));
exports.counterStorage = counterStorage;
const updateLocalStorage = (fifteen) => localStorage.setItem('fifteen', JSON.stringify(fifteen));
exports.updateLocalStorage = updateLocalStorage;
const updateLocalCounter = (counter) => localStorage.setItem('counter', JSON.stringify(counter));
exports.updateLocalCounter = updateLocalCounter;
