"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.resetCounter = exports.initCounter = exports.fifteenCounter = exports.generateGame = void 0;
const localStorage_1 = require("./localStorage");
const createGridRow = (gridRow, parent) => {
    const gridRowDOM = document.createElement('div');
    gridRowDOM.className = 'grid__row';
    parent.appendChild(gridRowDOM);
    return gridRow.map(() => createGridCell(gridRowDOM));
};
const createGridCell = (parent) => {
    const gridCellDom = document.createElement('div');
    gridCellDom.className = 'grid__cell';
    return parent.appendChild(gridCellDom);
};
const generateGame = ({ field, gridContainer, tileContainer, matrix }) => {
    generateGridField(matrix, gridContainer);
    field.map((innerText) => generateTileField(tileContainer, String(innerText)));
};
exports.generateGame = generateGame;
const generateGridField = (matrix, parent) => {
    return matrix.map((gridRow) => createGridRow(gridRow, parent));
};
const generateTileField = (parent, innerText) => {
    const tile = document.createElement('div');
    tile.className = 'tile';
    tile.dataset.matrixId = innerText;
    tile.innerText = innerText;
    if (innerText === '0') {
        tile.style.opacity = '0';
    }
    parent.appendChild(tile);
};
exports.fifteenCounter = document.querySelector('#fifteenCounter');
const initCounter = () => {
    console.log(123);
    if ((0, localStorage_1.counterStorage)()) {
        return exports.fifteenCounter.innerHTML = (0, localStorage_1.counterStorage)();
    }
    return (0, localStorage_1.updateLocalCounter)(0);
};
exports.initCounter = initCounter;
const resetCounter = () => {
    exports.fifteenCounter.innerHTML = '0';
    (0, localStorage_1.updateLocalCounter)(0);
};
exports.resetCounter = resetCounter;
