"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.incrementCounter = exports.shuffleField = exports.checkWin = exports.firstZeroConcat = exports.isValidForSwap = exports.findCoordByNumber = exports.getMatrix = void 0;
const DOM_1 = require("./DOM");
const localStorage_1 = require("./localStorage");
const getMatrix = (arr, size) => {
    const matrix = [];
    arr.map(() => matrix.push(arr.splice(0, size)));
    return matrix;
};
exports.getMatrix = getMatrix;
const findCoordByNumber = (num, matrix) => {
    for (let y = 0; y < matrix.length; y++) {
        for (let x = 0; x < matrix.length; x++) {
            if (matrix[y][x] === num) {
                return { x, y };
            }
        }
    }
    return undefined;
};
exports.findCoordByNumber = findCoordByNumber;
const isValidForSwap = (coords1, coords2) => {
    const diffX = Math.abs(coords1.x - coords2.x);
    const diffY = Math.abs(coords1.y - coords2.y);
    if ((diffX === 1 || diffY === 1) && (coords1.x === coords2.x || coords1.y === coords2.y)) {
        return 'short-swap';
    }
    if (((diffX === 0 && diffY !== 0) || (diffX !== 0 && diffY === 0))) {
        return 'long-swap';
    }
    return false;
};
exports.isValidForSwap = isValidForSwap;
const firstZeroConcat = (row, blank, end) => [].concat(blank, row.slice(0, end));
exports.firstZeroConcat = firstZeroConcat;
const checkWin = (matrix, matrixWin) => {
    if (JSON.stringify(matrix) === JSON.stringify(matrixWin)) {
        alert('победа');
    }
};
exports.checkWin = checkWin;
const shuffleField = (field) => ([...field].sort(() => Math.random() - .5).concat(0));
exports.shuffleField = shuffleField;
const incrementCounter = () => {
    let counter = Number(DOM_1.fifteenCounter.innerHTML);
    DOM_1.fifteenCounter.innerHTML = String(++counter);
    (0, localStorage_1.updateLocalCounter)(counter);
};
exports.incrementCounter = incrementCounter;
